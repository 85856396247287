import React from 'react';
import ImageDisplay from './ImageDisplay';
import PdfViewer from './PdfViewer';

import bachelorDegree from '../../src/assets/img/portfolio/degree.jpg';
import bachelorAcademicTranscript from '../../src/assets/img/portfolio/academic-transcript.jpg';
import paperAcceptanceLetter from '../../src/assets/img/portfolio/paper-acceptance-letter.jpg';

import transcriptVerificationOriginal
    from '../../src/assets/pdf/1 - Verification of Academic Transcript 中国高等学校学生成绩验证报告(SITENDA AMOS).pdf';
import transcriptVerificationEnglish
    from '../../src/assets/pdf/2 - Verification Report of China Higher Education Students Academic Transcript(SITENDA AMOS).pdf';
import bachelorDegreeVerification
    from '../../src/assets/pdf/1 - 中国高等教育学位在线验证报告 (China Higher Education Degree Online Verification Report) SITENDA AMOS.pdf';
import graduationCertificateVerification
    from '../../src/assets/pdf/1 - 教育部学历证书电子注册备案表 Graduation Certificate Online Verification Report SITENDA AMOS.pdf';
import graduationCertificate
    from '../../src/assets/pdf/2 - 学历证书 Bachelor Graduation Certificate Original Chinese.pdf';
import ieltsTestReportForm from '../../src/assets/pdf/Sitenda Amos IELTS.pdf';
import hsk4TestReportForm from '../../src/assets/pdf/Sitenda Amos HSK 4 (汉语水平考试结果) full.pdf';

import previewVerificationOfAcademicTranscriptOriginal
    from '../../src/assets/img/previews/preview -1 - Verification of Academic Transcript 中国高等学校学生成绩验证报告(SITENDA AMOS).png';
import previewVerificationOfAcademicTranscriptEnglish
    from '../../src/assets/img/previews/preview -2 - Verification Report of China Higher Education Students Academic Transcript(SITENDA AMOS).png';
import previewBachelorDegreeVerification
    from '../../src/assets/img/previews/preview -1 - 中国高等教育学位在线验证报告 (China Higher Education Degree Online Verification Report) SITENDA AMOS.png';
import previewGraduationCertificate
    from '../../src/assets/img/previews/preview -2 - 学历证书 Bachelor Graduation Certificate Original Chinese.png';
import previewGraduationCertificateVerification
    from '../../src/assets/img/previews/preview -1 - 教育部学历证书电子注册备案表 Graduation Certificate Online Verification Report SITENDA AMOS.png';
import previewIeltsTestReportForm from '../../src/assets/img/previews/preview - Sitenda Amos IELTS.png';
import previewHsk4TestReportForm
    from '../../src/assets/img/previews/preview - Sitenda Amos HSK 4 (汉语水平考试结果) full.png';

const Portfolio = () => {
    const portfolioItems = [{
        type: 'image',
        img: bachelorDegree,
        title: "Bachelor's Degree",
        description: "This is my Bachelor Degree certificate obtained from SDUST.",
        filter: 'academic'
    }, {
        type: 'pdf',
        pdf: bachelorDegreeVerification,
        preview: previewBachelorDegreeVerification,
        title: "Bachelor's Degree Verification",
        description: "This is the CHSI Verification document that certifies the authenticity of my bachelor's degree.",
        filter: 'academic'
    }, {
        type: 'image',
        img: bachelorAcademicTranscript,
        title: "Academic Transcript",
        description: "This transcript contains all my academic records during my undergraduate studies at SDUST.",
        filter: 'academic'
    },

        {
            type: 'pdf',
            pdf: transcriptVerificationOriginal,
            preview: previewVerificationOfAcademicTranscriptOriginal,
            title: "Academic Transcript Verification",
            description: "This is the original verification document.",
            filter: 'academic'
        }, {
            type: 'pdf',
            pdf: transcriptVerificationEnglish,
            preview: previewVerificationOfAcademicTranscriptEnglish,
            title: "Academic Transcript Verification (English)",
            description: "This is the translation of the CHSI Verification for my academic transcript.",
            filter: 'academic'
        }, {
            type: 'pdf',
            pdf: graduationCertificate,
            preview: previewGraduationCertificate,
            title: "Graduation Certificate",
            description: "This is the graduation certificate for my undergraduate studies at SDUST.",
            filter: 'academic'
        }, {
            type: 'pdf',
            pdf: graduationCertificateVerification,
            preview: previewGraduationCertificateVerification,
            title: "Graduation Certificate Verification",
            description: "This is the CHSI Verification document that certifies the authenticity of graduation certificate.",
            filter: 'academic'
        },

        {
            type: 'image',
            img: paperAcceptanceLetter,
            title: "Paper Acceptance Letter",
            description: "This letter confirms the acceptance of my research paper in the Journal of Network Intelligence.",
            filter: 'academic'
        }, {
            type: 'pdf',
            pdf: ieltsTestReportForm,
            preview: previewIeltsTestReportForm,
            title: "IELTS Test Report",
            description: "Official IELTS Test Report showcasing my results from the International English Language Testing System. Achieved an overall Band 7, reflecting proficiency in English language skills across listening, reading, writing, and speaking.",
            filter: 'academic'
        }, {
            type: 'pdf',
            pdf: hsk4TestReportForm,
            preview: previewHsk4TestReportForm,
            title: "HSK-4 Test Report",
            description: "Official HSK-4 Test Report detailing my score and performance analysis in the Chinese Proficiency Test (HSK). Achieved a score of 203 out of 300, demonstrating solid proficiency in Mandarin across listening, reading, and writing skills.",
            filter: 'academic'
        },];

    return (<section id="portfolio" className="portfolio section-bg">
        <div className="container">

            <div className="section-title">
                <h2>Credentials & Certificates</h2>
                <p>Here you can find a collection of my academic achievements and work credentials.</p>
            </div>

            <div className="row" data-aos="fade-up">
                <div className="col-lg-12 d-flex justify-content-center">
                    <ul id="portfolio-flters">
                        <li data-filter="*" className="filter-active">All</li>
                        <li data-filter=".filter-academic">Academic</li>
                        <li data-filter=".filter-leadership">Leadership</li>
                        <li data-filter=".filter-cocurricular">Co-curricular</li>
                    </ul>
                </div>
            </div>

            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
                {portfolioItems.map((item, index) => (item.type === 'image' ? (
                    <ImageDisplay key={index} item={item}/>) : (<PdfViewer key={index} item={item}/>)))}
            </div>
        </div>
    </section>);
};

export default Portfolio;