import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../../util/initials";
import {regex} from "../../util/regex";

const validateRegistrationPasswords = (state) => {
    state.validRegistration["validPassword"] = regex.PASSWORD_REGEX.test(state.registrationInputs["password"]);
    state.validRegistration["validConfirmPassword"] = Boolean(state.registrationInputs["password"].length > 0) && state.registrationInputs["password"] === state.registrationInputs["confirmPassword"];
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { user, accessToken, persist, title } = action.payload;
            state.user = user;
            state.token = accessToken;
            state.persist = persist;
            state.title = title;
        },
        resetAll: (state, action) => {
            state = {
                ...initialState
            }
        },
        setItem: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        setObjectItem: (state, action) => {
            const { key, innerKey, value } = action.payload;
                // For other keys or values, set them as usual
                const objectInState = state[key];
                objectInState[innerKey] = value;

        },
        handleBlur: (state, action) => {
            const { inputsObject, objectName, inputName, regexPattern : regexString } = action.payload;

            const regexPattern = regex[regexString];
            const result = regexPattern.test(inputsObject[inputName]);
            const validInnerKey = `valid${inputName.charAt(0).toUpperCase() + inputName.slice(1)}`;
            const validKey = `valid${objectName.charAt(0).toUpperCase() + objectName.slice(1)}`;
            const validObjectInState = state[validKey];
            validObjectInState[validInnerKey] = result;

            const focusKey = `${objectName}Focus`;
            const focusInnerKey = `${inputName}Focus`;
            const focusObjectInState = state[focusKey];
            focusObjectInState[focusInnerKey] = false;

            if (objectName === "registration" && (inputName === "password" || inputName === "confirmPassword")) {
                validateRegistrationPasswords(state);
            }
            if (objectName === "bankAccount") {
                state.bankAccountInputs["clientId"] = state.user["userId"];
                state.validBankAccount["validClientId"] = true;
            }
        },
        handleFocus: (state, action) => {
            const { objectName, inputName } = action.payload;
            const focusKey = `${objectName}Focus`;
            const focusInnerKey = `${inputName}Focus`;
            const focusObjectInState = state[focusKey];
            focusObjectInState[focusInnerKey] = true;
        },
        handleValidation: (state, action) => {
            const { objectName, eventValue, inputName, regexPattern: regexString } = action.payload;

            const regexPattern = regex[regexString];
            const validKey = `valid${objectName.charAt(0).toUpperCase() + objectName.slice(1)}`;
            const inputsKeys = `${objectName}Inputs`;
            const validObject = state[validKey];
            const inputsObjectInState = state[inputsKeys];

            const result = regexPattern.test(inputsObjectInState[inputName]);

            if ((inputName.trim().toLowerCase() === "email" || inputName.trim().toLowerCase() === "phonenumber") && result)
                state.loginSpecifics["identifier"] = inputName.trim();
            const innerKey = `valid${inputName.charAt(0).toUpperCase() + inputName.slice(1)}`;
            inputsObjectInState[inputName] = eventValue;
            validObject[innerKey] = result;
            state.eventProperties["isError"] = false;

            if (objectName === "registration" && (inputName === "password" || inputName === "confirmPassword")) {
                validateRegistrationPasswords(state);
            }
            if (objectName === "bankAccount") {
                state.bankAccountInputs["clientId"] = state.user["userId"];
                state.validBankAccount["validClientId"] = true;
                if (inputName === "bankId" && eventValue !== 18) {
                    state.bankAccountInputs["bankName"] = '';
                }
            }
        }
    }
});

export const {
    setItem,
    setObjectItem,
    handleBlur,
    handleFocus,
    handleValidation
} = authSlice.actions;

export const selectShowSideNav = (state) => state.auth.showSideNav

export default authSlice.reducer;
