import React from 'react';
import profileImg from '../../src/assets/img/profile-img.jpg';

const About = () => {
    return (
        <section id="about" className="about">
            <div className="container">

                <div className="section-title">
                    <h2>Introduction</h2>
                    <p>
                        Embarking on a burgeoning career path, a software engineering graduate
                        is nearing the completion of a Master's of Philosophy in Computer Science and Technology,
                        specializing in Computer Software and Theory. Proficient across multiple software development
                        platforms, various skills have been honed through practical experience. Academic pursuits have
                        culminated in two published research papers in the field of Computer Science, demonstrating a
                        commitment to
                        scholarly inquiry and innovation. Beyond academia, recognition as a leader and active
                        participation
                        in sports embody a well-rounded approach to both professional and personal endeavors.
                    </p>
                </div>

                <div className="row">
                    <div className="col-lg-4" data-aos="fade-right">
                        <img src={profileImg} className="img-fluid" alt="Profile"/>
                    </div>
                    <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <h3>Web, Desktop, &amp; Mobile.</h3>
                        <p className="fst-italic">
                            With experience gained through various software development projects,
                            I've grown into a formidable developer with skills in state-of-the-art full stack
                            web applications using various frameworks like Spring Boot and MERN stack.
                            Skilled in desktop development in C++, Nodejs, and Java, as well as Android development
                            in Java and the MVVM framework.
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    <li>
                                        <i className="bi bi-chevron-right"></i> <strong>Website:</strong>
                                        <span>
                                            <a
                                                style={{textDecoration: 'none'}}
                                                href="https://amossitenda.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                https://amossitenda.com
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <i className="bi bi-chevron-right"></i> <strong>LinkedIn:</strong>
                                        <span>
                                            <a
                                                style={{textDecoration: 'none'}}
                                                href="https://www.linkedin.com/in/amossitenda"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                www.linkedin.com/in/amossitenda
                                            </a>
                                        </span>
                                    </li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong>
                                        <span>
                                            <a
                                                style={{textDecoration: 'none'}}
                                                href="mailto:amossitenda@gmail.com"
                                            >
                                                amossitenda@gmail.com
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>23</span>
                                    </li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Bachelor of Software Engineering</span>
                                    </li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Qingdao, China</span>
                                    </li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong>
                                        <span>Available</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default About;