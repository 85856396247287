import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Skills from './components/Skills';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
// import Services from './components/Services';
// import Testimonials from './components/Testimonials';
// import Contact from './components/Contact';
import Footer from './components/Footer';
import {useSelector} from "react-redux";
import { selectShowSideNav } from "./features/auth/authSlice";

function App() {
    const showSideNav = useSelector(selectShowSideNav);

    return (<div className="App">
        {showSideNav && <Header/>}
        <Hero/>
        <main id="main">
            <About/>
            <Skills/>
            <Resume/>
            <Portfolio/>
            {/*<Services/>*/}
            {/*<Testimonials/>*/}
            {/*<Contact/>*/}
        </main>
        {showSideNav && <Footer/>}
    </div>);
}

export default App;
