import React, {useState} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import {selectShowSideNav, setItem} from "../features/auth/authSlice";
import {useDispatch, useSelector} from "react-redux";

const ImageDisplay = ({item}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const showSideNav = useSelector(selectShowSideNav);

    const showModal = (e) => {
        if (!showSideNav) {
            dispatch(setItem({key: 'showSideNav', value: true}));
            setIsModalVisible(false);
            return; // Do nothing, let click propagate
        } else {
            setIsModalVisible(true);
            dispatch(setItem({key: 'showSideNav', value: false}));
        }
    };

    const handleCancel = () => {
        dispatch(setItem({key: 'showSideNav', value: true}));
        setIsModalVisible(false);
    };

    return (


        <div className={`col-lg-4 col-md-6 portfolio-item filter-${item.filter}`} onClick={showModal}>
            <div className="portfolio-wrap">
                <div>
                    {item?.img ? (
                        <>
                            <img
                                src={item.img}
                                alt={item.title}
                                style={{
                                    width: 200, // Set reasonable portfolio size
                                    height: 150,
                                    objectFit: 'cover', // Ensure the image fits nicely
                                    cursor: 'pointer',
                                    borderRadius: '8px', // Optional for rounded corners
                                }}
                                onClick={showModal} // Handle image click
                            />
                            <Dialog
                                open={isModalVisible}
                                onClose={handleCancel}
                                aria-labelledby="dialog-title"
                                maxWidth="xl"
                                fullScreen
                            >
                                <DialogTitle id="dialog-title">{item.title}</DialogTitle>
                                <DialogActions>
                                    <Button onClick={handleCancel} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                                <DialogContent>
                                    <img
                                        src={item.img}
                                        alt={item.title}
                                        style={{width: '100%'}} // Full width in the dialog
                                    />
                                </DialogContent>
                            </Dialog>
                        </>
                    ) : (
                        <p>No image available</p>
                    )}
                </div>
                <div className="portfolio-info">
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                </div>
            </div>
        </div>
    );
};

export default ImageDisplay;