import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {selectShowSideNav, setItem} from "../features/auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";

function PdfViewer({item}) {
    const plugin = defaultLayoutPlugin();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showSideNav = useSelector(selectShowSideNav);
    const dispatch = useDispatch();

    const showModal = (e) => {
        if (!showSideNav) {
            // If the modal is visible, allow click propagation to the dialog
            return; // Do nothing, let click propagate
        } else {
            // Otherwise, toggle the modal visibility on click
            dispatch(setItem({key: 'showSideNav', value: false}));
            setIsModalVisible(true);
        }
    };

    const handleCancel = () => {
        dispatch(setItem({key: 'showSideNav', value: true}));
        setIsModalVisible(false);
    };

    return (
        <div className={`col-lg-4 col-md-6 portfolio-item filter-${item.filter}`} onClick={showModal}>
            <div className="portfolio-wrap">
                <div>
                    {item.preview ? (
                        <>
                            <img
                                src={item.preview}
                                alt={item.title}
                                style={{
                                    width: 200, // Match the size of image items
                                    height: 150,
                                    objectFit: 'cover', // Ensure the preview image fits nicely
                                    cursor: 'pointer',
                                    borderRadius: '8px', // Optional rounded corners
                                }}
                                onClick={showModal} // Handle preview image click
                            />
                            <Dialog
                                open={isModalVisible}
                                onClose={handleCancel}
                                aria-labelledby="dialog-title"
                                maxWidth="xl" // Allow the dialog to be as wide as possible
                                fullScreen // Enable full screen mode for the dialog
                            >
                                <DialogTitle id="dialog-title">{item.title}</DialogTitle>
                                <DialogActions>
                                    <Button onClick={handleCancel} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                                <DialogContent style={{height: '100%'}}>
                                    {item.pdf ? (
                                        <div style={{height: '100%'}}>
                                            <Worker
                                                workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
                                                <Viewer fileUrl={item.pdf} plugins={[plugin]}/>
                                            </Worker>
                                        </div>
                                    ) : (
                                        <p>No PDF available</p>
                                    )}
                                </DialogContent>
                            </Dialog>
                        </>
                    ) : (
                        <p>No preview available</p>
                    )}
                </div>
                <div className="portfolio-info">
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                </div>
            </div>
        </div>
    );
}

export default PdfViewer;