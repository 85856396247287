import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';


const Skills = () => {
  return (
      <section id="skills" className="skills section-bg">
        <div className="container">

          <div className="section-title">
            <h2>Skills</h2>
            <p>A highlight of the major skills I possess, earned from a variety of
              professional classes and research, practised while at internship,
              and perfected through several projects and a continued relearning.
              The details of these are evident in the portfolio section.</p>
          </div>

          <div className="row skills-content">

            <div className="col-lg-6" data-aos="fade-up">

              <div className="progress">
                <span className="skill">Java<i className="val">90%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-success" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">C / C++ <i className="val">90%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-info" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">Data Structures and Algorithms <i className="val">85%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-warning" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">JavaScript <i className="val">95%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-primary" role="progressbar" style={{ width: "95%" }} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">Node.js <i className="val">85%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-danger" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">Database Management Systems <i className="val">85%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-secondary" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

            </div>

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">

              <div className="progress">
                <span className="skill">Spring / Spring Boot <i className="val">85%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-success" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">React / Redux <i className="val">85%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-info" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">Vue.js <i className="val">75%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-warning" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">Android Development <i className="val">85%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-primary" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">WeChat / WeCom Development <i className="val">85%</i></span>
                <div className="progress-bar-wrap">
                  <div className="progress-bar bg-danger" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </section>
  )
}

export default Skills