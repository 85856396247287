import React, {useEffect, useState} from 'react';
import profileImg from '../../src/assets/img/profile-img.jpg';
import {Drawer, List, ListItem, ListItemText, IconButton, Fab} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';  // Keep the Material UI hamburger icon for mobile toggle
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [showFab, setShowFab] = useState(false);
    const isMobile = useMediaQuery('(max-width: 1200px)'); // Detect mobile screens

    const handleHamburgerIconClick = () => {
        setIsDrawerOpen(true); // Open the drawer when the hamburger icon is clicked
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false); // Close the drawer when needed
    };

    useEffect(() => {
        let scrollTimeout;
        const handleScroll = () => {
            if (isMobile) {
                setShowFab(true); // Show the floating button when scroll is detected
                clearTimeout(scrollTimeout); // Reset the timer if scrolling continues
                scrollTimeout = setTimeout(() => {
                    setShowFab(false); // Hide the button after 3 seconds of no scrolling
                }, 3000);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(scrollTimeout); // Clean up timeout when component unmounts
        };
    }, [isMobile]);

    return (
        <>
            {/* Floating button for mobile devices */}
            {showFab && isMobile && (
                <Fab
                    color="primary"
                    aria-label="menu"
                    onClick={handleHamburgerIconClick}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        zIndex: 999999,
                        backgroundColor: '#007bff'
                    }}
                >
                    <MenuIcon/>
                </Fab>
            )}

            {/* Hamburger icon for mobile devices */}
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleHamburgerIconClick}
                className="d-xl-none"
                sx={{zIndex: 999999}}
            >
                <MenuIcon/>
            </IconButton>

            {/* Drawer for mobile navigation */}
            <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
                <div style={{
                    width: 250,
                    backgroundColor: '#000',
                    height: '100%' /* Set to the same background color as the desktop menu */
                }}>
                    <List>
                        <ListItem button component="a" href="#hero" onClick={() => setIsDrawerOpen(false)}>
                            <i className="bx bx-home" style={{marginRight: '10px'}}></i>
                            <ListItemText primary="Home" className="text-light" style={{fontWeight: 'normal'}}/>
                        </ListItem>
                        <ListItem button component="a" href="#about" onClick={() => setIsDrawerOpen(false)}>
                            <i className="bx bx-user" style={{marginRight: '10px'}}></i>
                            <ListItemText primary="About" className="text-light" style={{fontWeight: 'normal'}}/>
                        </ListItem>
                        <ListItem button component="a" href="#skills" onClick={() => setIsDrawerOpen(false)}>
                            <i className="bx bx-brain" style={{marginRight: '10px'}}></i>
                            <ListItemText primary="Skills" className="text-light" style={{fontWeight: 'normal'}}/>
                        </ListItem>
                        <ListItem button component="a" href="#resume" onClick={() => setIsDrawerOpen(false)}>
                            <i className="bx bx-file-blank" style={{marginRight: '10px'}}></i>
                            <ListItemText primary="Resume" className="text-light" style={{fontWeight: 'normal'}}/>
                        </ListItem>
                    </List>
                </div>
            </Drawer>

            <header id="header">
                <div className="d-flex flex-column">
                    <div className="profile">
                        <img src={profileImg} alt="Profile" className="img-fluid rounded-circle"/>
                        <h1 className="text-light">
                            <a href="#">Amos Sitenda</a>
                        </h1>
                        <div className="social-links mt-3 text-center">
                            <a href="https://www.facebook.com/sitenda.irenaeusamos.9" className="facebook"><i
                                className="bx bxl-facebook"></i></a>
                            <a href="https://www.linkedin.com/in/amossitenda" className="linkedin"><i
                                className="bx bxl-linkedin"></i></a>
                        </div>
                    </div>

                    {/* Desktop navigation */}
                    <nav id="navbar" className="nav-menu navbar d-none d-xl-block">
                        <ul>
                            <li>
                                <a href="#hero" className="nav-link scrollto active">
                                    <i className="bx bx-home" style={{marginRight: '10px'}}></i>
                                    <span style={{fontWeight: 'lighter'}}>Home</span>
                                </a>
                            </li>
                            <li>
                                <a href="#about" className="nav-link scrollto">
                                    <i className="bx bx-user" style={{marginRight: '10px'}}></i>
                                    <span style={{fontWeight: 'normal'}}>About</span>
                                </a>
                            </li>
                            <li>
                                <a href="#skills" className="nav-link scrollto">
                                    <i className="bx bx-brain" style={{marginRight: '10px'}}></i>
                                    <span style={{fontWeight: 'normal'}}>Skills</span>
                                </a>
                            </li>
                            <li>
                                <a href="#resume" className="nav-link scrollto">
                                    <i className="bx bx-file-blank" style={{marginRight: '10px'}}></i>
                                    <span style={{fontWeight: 'normal'}}>Resume</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default Header;