import React from 'react'

const Resume = () => {
    return (
        <section id="resume" className="resume">
            <div className="container">

                <div className="section-title">
                    <h2>Resume</h2>
                    <p>
                        A wrap-up of a career built through rigorous hardwork, study,
                        training and exposure.
                    </p>
                </div>

                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                        <h3 className="resume-title">Sumary</h3>
                        <div className="resume-item pb-0">
                            <h4>Amos Sitenda</h4>
                            <p><em>Professional Software Engineer and active master's student with a
                                wide-ranging experience in software development across various platforms.
                                Gained through diverse projects and a comprehensive internship, my practical
                                knowledge has shaped me into a reliable and skilled engineer.</em></p>
                            <ul>
                                <li>Qingdao, China</li>
                                <li>amossitenda@gmail.com</li>
                            </ul>
                        </div>

                        <h3 className="resume-title">Education</h3>
                        <div className="resume-item">
                            <h4>Master of Philosophy in Computer Software & Application</h4>
                            <h5>Sept. 2023 - Present</h5>
                            <p><em>Shandong University of Science and Technology, located in Qingdao city, P.R.
                                China.</em></p>
                            <p>During my tenure here, I've engaged in comprehensive research focused on metaheuristic
                                optimization
                                algorithms and their diverse applications across various domains of science and
                                industry.</p>
                            <p>In collaboration with my supervisors, I've contributed to the development of a pioneering
                                mutation
                                strategy within the framework of Differential Evolution. Our findings were published in
                                the esteemed
                                Journal of Network Intelligence.</p>
                            <div className="paper-details">
                                <p><strong>Paper Details:</strong></p>
                                <ul>
                                    <li><strong>Paper ID:</strong> JNI-S-2023-10-021</li>
                                    <li><strong>Paper Title:</strong> Differential Evolution Strategy with Chebyshev
                                        Chaos Based Mutation
                                    </li>
                                    <li><strong>Authors:</strong> Amos Sitenda, Pei-Cheng Song, Shu-Chuan Chu, Shi-Huang
                                        Chen
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="resume-item">
                            <h4>Bachelor of Software Engineering</h4>
                            <h5>Sept. 2019 - 2023</h5>
                            <p><em>Shandong University of Science and Technology, Qingdao city, P.R. China.</em></p>
                            <p>During my undergraduate studies, I acquired a solid foundation in professional software
                                engineering
                                concepts and practices, encompassing programming languages, database management,
                                software design
                                patterns and principles, agile development methodologies, and platform-specific
                                engineering.</p>
                            <p>In addition to technical coursework, I pursued fluency in the Chinese language, which now
                                serves as my
                                third international language, alongside English and French.</p>
                            <div className="paper-details">
                                <p><strong>Paper Details:</strong></p>
                                <ul>
                                    <li><strong>Journal:</strong> Journal of Intelligent & Fuzzy Systems 42 (2022)
                                        5385–5398
                                    </li>
                                    <li><strong>DOI:</strong> 10.3233/JIFS-211932</li>
                                    <li><strong>Publisher:</strong> IOS Press</li>
                                    <li><strong>Paper Title:</strong> A Comparative Study on Chinese Open Domain
                                        Suggestion Mining
                                    </li>
                                    <li><strong>Authors:</strong> Hua Zhao, PeiXin Zhang, Yue Liang, and Sitenda Amos
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                        <h3 className="resume-title">Professional Experience</h3>
                        <div className="resume-item">
                            <h4>WeCom Development - Internship</h4>
                            <h5>May 2023 - June 2023</h5>
                            <p><em>Qingdao Assetech Information Technology Co. Ltd., Laoshan district, Qingdao city,
                                China</em></p>
                            <ul>
                                <li>Responsible for code migration from native mobile applications into the WeCom
                                    (WeChat Enterprise)
                                    development environment.
                                </li>
                                <li>Assisted my internship supervisor in frontend UI development mainly using APICloud,
                                    React, and
                                    Vue.js.
                                </li>
                                <li>Contributed to the development of various Enterprise Asset Management (EAM) software
                                    solutions.
                                </li>
                            </ul>
                        </div>
                        <div className="resume-item">
                            <h4>Researcher on Physics Team</h4>
                            <h5>Feb 2019 - May 2019</h5>
                            <p><em>Simplicity Bookstore, Plot 15 Luwum Street, Kampala city, Uganda</em></p>
                            <ul>
                                <li>Member of the Physics research team, responsible for finding the most comprehensible
                                    solutions to
                                    national Physics exam problems for students.
                                </li>
                                <li>Cooperated with team members in documenting easy-to-understand solutions for a
                                    Physics solutions
                                    book.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Resume