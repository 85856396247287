import React, {useEffect} from 'react';
import Typed from 'typed.js';

const Hero = () => {
    useEffect(() => {
        const options = {
            strings: ['Software Engineer', 'Computer Scientist', 'Mobile Developer', 'Desktop Developer'],
            typeSpeed: 50,
            backSpeed: 50,
            loop: true,
        };

        const typed = new Typed('.typed', options);

        // Cleanup on unmount
        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
            <div className="hero-container" data-aos="fade-in">
                <h1>Amos Sitenda</h1>
                <p>I'm <span className="typed" data-typed-items="Software Engineer, Computer Scientist, Mobile Developer, Desktop Developer"></span>
                </p>
            </div>
        </section>
    );
}

export default Hero;
