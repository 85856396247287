import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import { backend } from "../util/backend";

export const store = configureStore({
    reducer: {
        auth: authReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware(),
    devTools: backend.env !== 'production'
});
